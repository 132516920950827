/****************************************************/
// Capa de Servicios para el modulo de Autenticacion.

import axios from "axios";
import store from "@/store/store.js";
import router from "@/router";
import investorService from '@/providers/InvestorService';

export default new class {
    
    #baseUrl = null;
    data = null;
    user = null;
    status = null;
    error_message = "";
    
    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL + "/auth";
    }
    
    // { headers: {
    //     "Content-Type": "application/json"
    //     Authorization: "Bearer " + apiToken,
    //      'Access-Control-Allow-Origin': '*'
    // }
    
    async loginUser(passport) {
        let result = {
            valid: false,
            status: 0,
            isAdmin: false,
            roles: '',
            messageText: '',
            token: '',
            active: false
        };
        // console.log('Credenciales:', passport);
        try {
            let response = await axios.post(this.#baseUrl + "/login", passport, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }
            );
            this.data = response.data;
            // console.log('Login data:', this.data);
            if (this.data.success) {
                result.valid = response.data.success;
                result.status = 200;
                result.isAdmin = response.data.user.is_admin;
                result.roles = response.data.user.roles;
                result.messageText = 'Usuario autorizado...';
                result.token = this.data.access_token;
                result.active = response.data.user.active;
                // console.log('Login result:', this.result);
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                this.error_message = err.response.data.message;
                // console.log('Error message:', this.error_message);
            }
            result.valid = err.response.data.success;
            result.status = err.response.status;
            result.messageText = err.response.data.message;
            result.isAdmin = false;
            result.token = '';
            result.active = false;
        }
        return result;
    }
    
    signUp(passportData, picture_id, imageFile) {
        let result = null;
        let recordData = new FormData();
        recordData.append("name", passportData.name);
        recordData.append("last_name", passportData.last_name);
        recordData.append("email", passportData.email);
        recordData.append("password", passportData.password);
        recordData.append("roles", passportData.roles);
        recordData.append("active", passportData.active);
        axios.post(this.#baseUrl + "/signup", recordData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            }
        )
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
                let data = response.data;
                let user_id = data.id;
                this.uploadImage(user_id, picture_id, imageFile);
            })
            /* handle error */
            .catch(error => {
                    result = this.handleError(error);
                }
            );
        return result;
    }
    
    uploadImage(user_id, picture_id, imageFile) {
        if (imageFile) {
            let formData = new FormData();
            formData.append("user_id", user_id);
            formData.append("picture_id", picture_id);
            formData.append("file", imageFile);
            axios.post(this.#baseUrl + '/upload-image/users', formData)
                .then(response => {
                    return response.data;
                    // console.log('Respuesta: ', response.data);
                })
                /* handle error */
                .catch(error => {
                        return this.handleError(error);
                    }
                );
        }
    }
    
    redirectToPage(token, page) {
        axios.get(this.#baseUrl + "/user", {
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: 'Bearer ' + this.data.access_token,
            }
        })
            .then(response => {
                this.user = response.data;
                // console.log('Login User:', this.user);
                if (this.user) {
                    store.commit('SetUser', this.user);
                    store.commit('SetIslogin', true);
                    store.commit('SetToken', token);
                    if (page==='dashboard') {
                        router.push({path: "/admin-page"});
                    } else {
                        // console.log('Login User:', this.user);
                        investorService.getInvestor(this.user.id)
                            .then(
                                response => {
                                    // console.log('Response:', response);
                                    if (response.success) {
                                        let investor = response.value;
                                        // console.log('Inversionista:', investor);
                                        sessionStorage.setItem('investor', JSON.stringify(store.state));
                                        router.push({
                                            name: "InvestorProfile",
                                            params: {
                                                id: investor.id,
                                            }
                                        });
                                    } else {
                                        console.log('Error: ', response.message);
                                        // router.push({path: "/investor"});
                                    }
                                }
                            );
                    }
                } else {
                    this.error_message = response.data.status;
                    // router.push({path: "/investor"});
                }
            });
    }
    
    logoutUser(sessionAdmin) {
        // console.log('Datos del store: ', store.state);
        axios.get(this.#baseUrl + "/logout", {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: 'Bearer ' + store.getters.getToken
                }
            }
        )
            .then(response => {
                    sessionStorage.setItem('investor', null);
                    this.status = response.data.status;
                    // console.log('response:', response);
                    if (this.status === "success") {
                        store.dispatch('clearStateLogin');
                        store.commit('resetStateLogin');
                        if (sessionAdmin) {
                            router.push({path: "/homepage"});
                        } else {
                            router.push({path: "/investor-login"});
                        }
                        
                    }
                }
            )
            .catch(e => {
                if (e.response && e.response.status === 400) {
                    this.error_message = e.response.data.error;
                    // console.log('Error message:', this.error_message);
                }
            });
    }
    
    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        // console.log(error.config);
        return valueError;
    }
}
