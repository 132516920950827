<template>
    <v-navigation-drawer
            v-model="Sidebar_drawer"
            :dark="SidebarColor !== 'white'"
            :color="SidebarColor"
            mobile-breakpoint="960"
            clipped
            mini-variant-width="70"
            :expand-on-hover="expandOnHover"
            app
            id="main-sidebar">
        <!---User Area -->
        <v-list-item two-line class="profile-bg">
            <v-list-item-avatar color="grey darken-1">
                <v-img :src="user.pictures !== null ? user.pictures[0].url : require('@/assets/images/no-image.jpg')" v-if="getStatusLogin && user"></v-img>
                <v-icon x-large v-else>mdi-account</v-icon>
            </v-list-item-avatar>
            
            <v-list-item-content>
                <v-list-item-title class="caption" v-show="getStatusLogin" v-if="user">{{ user.name }} {{ user.last_name }}</v-list-item-title>
                <v-list-item-subtitle class="caption" v-if="user">{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <!---User Area -->
        
        <v-divider></v-divider>

        <v-list dense :expand="false">
            <v-list-group
                no-action
                :value="false"
                prepend-icon="mdi-account-multiple-outline">
                <template v-slot:activator>
                    <v-list-item-title>Administración</v-list-item-title>
                </template>
                <v-list-item
                    v-for="(item, idx) in admins"
                    :key="idx"
                    :to="item.link"
                    link>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
            <v-list-group
                :value="false"
                no-action
                prepend-icon="mdi-animation">
                <template v-slot:activator>
                    <v-list-item-title>Secciones</v-list-item-title>
                </template>
                <template v-for="(item, idx) in sections">
                    <v-list-item
                        :key="idx"
                        :to="item.link"
                        link
                        v-if="!item.grouped">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-icon v-if="false">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-group
                        :value="true"
                        no-action
                        sub-group
                        :key="idx"
                        v-else>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                            v-for="(node, i) in item.children"
                            :key="i"
                            :to="node.link"
                            link>
                            <v-list-item-title v-text="node.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import { mapState } from "vuex";
    import VerticalSidebarItems from "./VerticalSidebarItems";

    export default {
        name: "VerticalSidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            items: VerticalSidebarItems,
            user: null,
            admins: [
                {title: 'Organización', icon: 'mdi-cog-outline', link: '/admin-company', active: true, grouped: false},
                {title: 'Usuarios', icon: 'mdi-account-circle', link: '/admin-user', active: true, grouped: false},
                {title: 'Contactos', icon: 'mdi-account-multiple', link: '/admin-contacts', active: true, grouped: false},
                {title: 'Preferencias', icon: 'mdi-checkbox-marked-outline', link: '/admin-preferences',
                    active: true, grouped: false},
                {title: 'Proyectos', icon: 'mdi-codepen', link: '/admin-projects', active: true, grouped: false},
                {title: 'Avances', icon: 'mdi-chart-gantt', link: '/admin-advances', active: true, grouped: false},
                {title: 'Categorías', icon: 'mdi-format-list-bulleted', link: '/admin-categories', active: true, grouped: false},
                {title: 'Asignaciones', icon: 'mdi-account', link: '/admin-investors', active: true, grouped: false}
            ],
            sections: [
                {title: 'Slider del Portal', icon: null, link: '/admin-sliders', active: true, grouped: false},
                {title: 'Slogan', icon: null, link: '/admin-slogan', active: true, grouped: false},
                {title: 'Estadísticas', icon: null, link: '/admin-statistic', active: true, grouped: false},
                {title: 'Quienes Somos', icon: null, link: '/admin-about-us', active: true, grouped: false},
                {
                    title: 'Estrategias',
                    icon: null,         // link: '/admin-strategy', // Valores, Mision y Vision
                    link: null,
                    active: true,
                    grouped: true,
                    children: [
                        {title: "Misión", icon: null, link: "/admin-mision", active: true, grouped: false},
                        {title: "Visión", icon: null, link: "/admin-vision", active: true, grouped: false},
                        {title: "Valores", icon: null, link: "/admin-values", active: true, grouped: false}
                    ]
                },
                {
                    title: "Banca Privada",
                    icon: null,
                    link: null,
                    active: true,
                    grouped: true,
                    children: [
                        {title: "Alianza GBM", icon: null, link: "/admin-alliance", active: true, grouped: false},
                        {title: "Mercado de Capital", icon: null, link: "admin-market", active: true, grouped: false},
                        {title: "Compra/Venta Divisas", icon: null, link: "admin-sale-purchase", active: true, grouped: false},
                        {title: "Fondos y Ahorros", icon: null, link: "/admin-refund", active: true, grouped: false},
                        {title: "Derivados Financieros", icon: null, link: "/admin-finance", active: true, grouped: false},
                        {title: "Plan de Pesión", icon: null, link: "/admin-pension", active: true, grouped: false}
                    ]
                },
                {title: "SOFOM", icon: null, link: "/admin-sofom", active: true, grouped: false},
                {title: "Desarrollo Inmobiliario", icon: null, link: "/admin-develop", active: true, grouped: false},
                {title: "Eventos y Espectáculos", icon: null, link: "/admin-events", active: true, grouped: false},
                {title: "Equipo de Trabajo", icon: null, link: "/admin-team", active: true, grouped: false},
            ],
        }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                },
            },
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },
        watch: {
            "$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            },
        },
        methods: { },
        mounted() {
            this.user = this.$store.getters.getUser;
        }
    };
</script>

7<style lang="scss">
    #main-sidebar {
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        
        .v-navigation-drawer__border {
            display: none;
        }
        .v-list {
            padding: 2px 2px;
        }
        .v-list-item {
            min-height: 35px;
            &__icon--text,
            &__icon:first-child {
                justify-content: center;
                text-align: center;
                width: 20px;
            }
        }
        .v-list-item__icon i {
            width: 20px;
        }
        .icon-size .v-list-group__items i {
            width: 16px;
            font-size: 16px;
        }
        .profile-bg {
            &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
                opacity: 1;
            }
            .v-avatar {
                padding: 15px 0;
            }
        }
        .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
</style>