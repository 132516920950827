/***********************************************/
// Capa de Servicios para el modulo de Investors.

import axios from "axios";
import store from "@/store/store.js";

export default new class {
    
    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };
    
    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }
    
    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/investors");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getViewList() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-list/investors");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/investors/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getInvestor(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/show-investor/investors?uid=${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async listInvestors() {
        try {
            let response = await axios.get(this.#baseUrl + "/section-list/investors");
            if (response.data.success) {
                //console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async listUsers() {
        try {
            let response = await axios.get(this.#baseUrl + "/list-users/investors");
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async addRecord(formData) {
        let result = null;
        let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("user_id", formData.user.id);
        recordData.append("investment_type", 'Inversion a plazo fijo.' /*formData.investment_type*/);
        try {
            let response = await axios.post(this.#baseUrl + "/investors", recordData);
            if (response.data.success) {
                let data = response.data;
                let investor_id = data.id;
                // Sincroniza las plataformas
                let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(investor_id, projectIds);
    
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }
    
    // Pushes posts to the server when called.
    async updateRecord(formData) {
        let result = null;
        let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("user_id", formData.user.id);
        recordData.append("investment_type", 'Inversion a plazo fijo.' /*formData.investment_type*/);
        recordData.append('_method', 'put');
        try {
            let response = await axios.post(`${this.#baseUrl}/investors/${formData.id}`, recordData);
            if (response.data.success) {
                let data = response.data;
                let investor_id = data.id;
    
                // Sincroniza las plataformas
                let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(investor_id, projectIds);
                
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }
    
    async deleteRecord(id) {
        try {
            let response = await axios.delete(`${this.#baseUrl}/investors/${id}`);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    registerProjects(investor_id, projects_ids) {
        let formData = new FormData();
        formData.append("investor_id", investor_id);
        formData.append("projects_ids", projects_ids);
        axios.post(this.#baseUrl + '/register-projects/investors', formData)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                return response.data;
            })
            /* handle error */
            .catch(error => {
                    return this.handleError(error);
                }
            );
    }
    
    async deleteProjects(investor_id, projects_ids) {
        try {
            let formData = new FormData();
            formData.append("investor_id", investor_id);
            formData.append("projects_ids", projects_ids);
            let response = await axios.post(this.#baseUrl + '/delete-projects/investors', formData);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }
}
