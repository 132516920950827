<template>
    <v-app id="admin" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
        <VerticalHeader v-model="expandOnHover"></VerticalHeader>
        <v-main>
            <v-container
                    class="py-8 px-6"
                    fluid>
                <transition
                        name="router-anim"
                        enter-active-class="fade-enter-active fade-enter"
                        leave-active-class="fade-leave-active fade-enter">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-main>
        <VerticalSidebar :expand-on-hover.sync="expandOnHover"></VerticalSidebar>
    </v-app>
</template>

<script>
    import VerticalHeader from "./vertical-header/VerticalHeader";
    import VerticalSidebar from "./vertical-sidebar/VerticalSidebar";
    import { mapState, mapMutations } from "vuex";
    // import "@/assets/scss/vuetify/overrides.scss";
    
    export default {
        name: 'AdminLayout',
        components: {
            VerticalHeader,
            VerticalSidebar
        },
        props: {
            source: String,
        },
        data: () => ({
            drawer: null,
            user: null,
            expandOnHover: false,
        }),
        computed: {
            ...mapState(["Customizer_drawer", "setHorizontalLayout"]),
            
        },
        methods: {
            ...mapMutations({
                setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
            }),
        },
        beforeMount() {
            this.user = this.$store.getters.getUser;
        }
    }
</script>

<style lang="scss" scoped src="@/assets/scss/vuetify/overrides.scss">
    // import "@/assets/scss/vuetify/overrides.scss";
</style>
